import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import ComponentWithBackgroundImage from "./ComponentWithBackgroundImage";
import HelpOverlay from "./HelpOverlay";

// Dynamically get the local network IP address
const localIPAddress = window.location.hostname;
const socket = io.connect(`${localIPAddress}:5000`);

const Home = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [projectImage, setProjectImage] = useState(""); // Initialize with an empty string or whatever default value you need
  const [isHelpVisible, setHelpVisible] = useState(false);

  const textInput = React.useRef();
  const clearInput = () => {
    textInput.current.value = ""; // Clear selected file name after upload
    setSelectedFile(null); // Clear selected file after upload
  };

  useEffect(() => {
    // Define a function to fetch the button status and project name
    const fetchData = async () => {
      try {
        await fetchFileList();
      } catch (error) {
        console.error("Error fetching fetchFileList:", error);
        fetchData(); // Retry fetching
      }
    };
    // Call the fetchData function
    fetchData();
  }, []); // Add retryCount as a dependency to re-trigger the effect

  useEffect(() => {
    socket.on("handleResponse", (data) => {
      const { type, message } = data;
      if (type === "file_list") {
        const fileList = JSON.parse(message);
        setFileList(fileList);
      }
    });

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off("handleResponse");
    };
  }, []);

  const fetchFileList = async () => {
    socket.emit("handleRequest", { type: "fetchFileList", message: "" });
  };

  const handleOptionClick = (fileName) => {
    const modifiedFileName = replaceExtensionWithJpg(fileName);
    const baseUrl = "http://" + localIPAddress + ":5000/projectImages/";
    const backgroundImageUrl = baseUrl + modifiedFileName;
    setProjectImage(backgroundImageUrl);
    setSelectedOption(fileName);
  };

  const handleClick = async (fileName) => {
    socket.emit("handleClick", { type: "setProjectFile", message: fileName });
    navigate(`/projects`);
  };

  const replaceExtensionWithJpg = (fileName) => {
    const parts = fileName.split(".");
    parts[parts.length - 1] = "jpg";
    return parts.join(".");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["pdprj", "jpg", "jpeg"];

    // Check if the selected file has the allowed extension
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFile(file);
    } else {
      clearInput();
      alert("Please select a file with the .pdprj .jpeg or .jpg extension.");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          // Replace spaces in the filename with %20
          const filename = encodeURIComponent(selectedFile.name);
          const content = event.target.result;
          socket.emit("upload_file", {
            name: filename,
            content: content,
          });
          console.log("Original Filename (React):", selectedFile.name);
          console.log("Encoded Filename (React):", filename);
        } catch (error) {
          // Handle the error
          console.error("Error uploading file:", error);
          alert("Error uploading file. Please try a different file.");
        }
      };

      clearInput();
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleShowHelp = () => {
    setHelpVisible(!isHelpVisible);
  };

  const handleCloseHelp = () => {
    setHelpVisible(false);
  };

  const bgImage = projectImage; // || "BitBusterBackground.jpg"; // Set default image if projectImage is empty

  return (
    <div>
      <div className="App" style={{ display: "flex" }}>
        <div style={{ flex: 1, marginTop: "30px", marginLeft: "84px" }} />
        <div
          className="App"
          style={{
            display: "flex",
            flex: 98,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column", // Added to stack the headings vertically
          }}
        >
          <h3 />
          <h1 style={{ color: "rgba(219,92,39,1)", margin: "0" }}>
            BIT BUSTER v0.1
          </h1>
          <h3 style={{ color: "rgba(219,92,39,0.7)", margin: "0" }}>
            AUTOMATED CONTROL SYSTEM TESTER
          </h3>
          <h3 />
        </div>
        <div
          style={{
            flex: 1,
            cursor: "pointer",
          }}
          type="button"
          onClick={handleShowHelp}
        >
          <img
            src={require("./help.png")}
            alt="Get Help"
            style={{
              width: "75px",
              height: "75px",
              marginTop: "30px",
              marginRight: "30px",
            }}
          />
          <HelpOverlay
            isVisible={isHelpVisible}
            onClose={handleCloseHelp}
            imageName="help_home.png"
          />
        </div>
      </div>
      <div className="App" style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            border: "3px solid #fff",
            padding: "0px",
            minHeight: "800px",
            maxHeight: "800px",
          }}
        >
          <>
            <h1 style={{ color: "rgba(219,92,39,1)" }}>Project Selector</h1>
            <div
              style={{
                border: "1px solid #fff",
              }}
            >
              <h2>File Upload</h2>
              <input
                type="file"
                onChange={handleFileChange}
                style={{
                  backgroundColor: "dark",
                  color: "white",
                  border: "2px solid #fff",
                  borderRadius: "5px",
                  padding: "8px",
                }}
                ref={textInput}
              ></input>
              <button
                onClick={handleUpload}
                style={{
                  backgroundColor: "dark",
                  color: "black",
                  border: "2px solid #fff",
                  borderRadius: "5px",
                  padding: "8px",
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >
                Upload
              </button>
            </div>
            <div>
              <h2>Select a File:</h2>
              <ul
                style={{
                  minHeight: "340px",
                  maxHeight: "340px",
                  overflowY: "auto",
                  textAlign: "left",
                }}
              >
                {fileList.map((fileName, index) => (
                  <li
                    key={index}
                    onClick={() => handleOptionClick(fileName)}
                    style={{
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    {fileName}
                  </li>
                ))}
              </ul>

              {selectedOption && (
                <div
                  style={{
                    backgroundColor: "dark",
                    color: "white",
                    border: "2px solid #fff",
                  }}
                >
                  <h3>Selected File:</h3>
                  <p>{selectedOption}</p>
                  {/* <button onClick={()=>navigate("/projects")}>Navigate To Project</button> */}
                  <div className="divNavigate">
                    <div
                      key={"Navigate to Project"}
                      style={{ margin: "0px" }}
                      type="button"
                      onClick={() => handleClick(selectedOption)}
                    >
                      {"Navigate To Project"}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
        <div
          style={{
            flex: 4,
            border: "3px solid #fff",
            backgroundImage: 'url("BitBusterBackground.jpg")',
            backgroundSize: "cover", // Adjusts the size of the image to cover the entire div
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
            overflow: "hidden", // Ensure the image doesn't overflow the container
            position: "relative", // Add relative positioning
            padding: "0px",
            minHeight: "800px",
            maxHeight: "800px",
          }}
        >
          <h2 style={{ color: "rgba(219,92,39,1)" }}>PROJECT DIAGRAM</h2>
          <ComponentWithBackgroundImage backgroundImageUrl={bgImage} />
        </div>
      </div>
    </div>
  );
};

export default Home;
