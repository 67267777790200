// HelpOverlay.js
import React from "react";
import "./HelpOverlay.css";

const HelpOverlay = ({ isVisible, onClose, imageName }) => {
  const handleImageClick = () => {
    onClose();
  };

  return (
    <div className={`help-overlay ${isVisible ? "visible" : ""}`}>
      <div className="image-container" onClick={handleImageClick}>
        <img src={`/images/${imageName}`} alt={`Help Image`} />
      </div>
    </div>
  );
};

export default HelpOverlay;
