import React, { useRef, useState, useEffect } from "react";

const ComponentWithBackgroundImage = ({ backgroundImageUrl }) => {
  const divRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    // Reset zoom and position when image changes
    setZoom(1);
    setCurrentPosition({ x: 0, y: 0 });
  }, [backgroundImageUrl]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;

      setCurrentPosition({
        x: currentPosition.x + deltaX,
        y: currentPosition.y + deltaY,
      });

      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleWheel = (e) => {
    e.preventDefault();

    const delta = e.deltaY || e.detail || e.wheelDelta;

    const newZoom = Math.max(0.2, Math.min(5, zoom + (delta < 0 ? 0.1 : -0.1)));
    setZoom(newZoom);
  };

  const handleDoubleClick = () => {
    setZoom(1);
    setCurrentPosition({ x: 0, y: 0 });
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "90%",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        cursor: isDragging ? "grabbing" : "grab",
        overflow: "hidden",
        position: "relative",
        transform: `translate(${currentPosition.x}px, ${currentPosition.y}px) scale(${zoom})`,
      }}
      ref={divRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onWheel={handleWheel}
      onDoubleClick={handleDoubleClick}
    ></div>
  );
};

export default ComponentWithBackgroundImage;
