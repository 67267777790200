import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import "./App.css"; // Import the CSS file
import HelpOverlay from "./HelpOverlay";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Help" element={<HelpOverlay />} />
      </Routes>
    </Router>
  );
}
