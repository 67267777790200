import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import HelpOverlay from "./HelpOverlay";

// Dynamically get the local network IP address
const localIPAddress = window.location.hostname;
const socket = io.connect(`${localIPAddress}:5000`);


const Projects = () => {
  const navigate = useNavigate();
  const [consoleOutput, setConsoleOutput] = useState("");
  const [buttons, setButtons] = useState([]);
  const [projectName, setProjectName] = useState("");
  const consoleRef = useRef(); // Create a ref for the console window
  const [needsRetry, setNeedsRetry] = useState(0);
  const [isHelpVisible, setHelpVisible] = useState(false);

  useEffect(() => {
    // Define a function to fetch the button status and project name
    const fetchData = async () => {
      try {
        await fetchProjectName();
        await fetchButtonStatus();
      } catch (error) {
        console.error("Error fetching data:", error);
        fetchData(); // Retry fetching
      }
    };

    // Call the fetchData function initially only if buttons are empty
    if (buttons.length === 0) {
      fetchData();
    }
  }, [buttons]); // Only trigger when buttons are empty

  useEffect(() => {
    socket.on("updateConsole", (data) => {
      enhancedSetConsoleOutput(data.message);
    });

    socket.on(
      "handleResponse",
      (data) => {
        const { type, message } = data;
        if (type === "button_status") {
          const buttonStatuses = JSON.parse(message);
          setButtons(buttonStatuses);
        } else if (type === "project_name") {
          const projectName = message;
          setProjectName(projectName);
        } else if (type === "button_status_single") {
          const buttonStatus = JSON.parse(message);
          const buttonLabel = buttonStatus.label;
          const buttonState = buttonStatus.state;
          const buttonEnable = buttonStatus.enabled;
          setButtonState(buttonLabel, buttonState, buttonEnable);
        }
        if (buttons.length === 0) {
          const needsRetry = true;
          setNeedsRetry(needsRetry);
        }
      },
      [buttons, setButtons, setProjectName, setButtonState]
    );

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off("updateConsole");
      socket.off("handleResponse");
    };
  }, []); // add buttons here if you want to get updated buttons every time console updates.

  useEffect(() => {
    // Original effect with needsRetry in the dependency array
    const fetchData = async () => {
      try {
        await fetchProjectName();
        await fetchButtonStatus();
      } catch (error) {
        console.error("Error fetching data:", error);
        fetchData(); // Retry fetching
      }
    };

    fetchData(); // Call the fetchData function initially
  }, [needsRetry]);

  const enhancedSetConsoleOutput = (newOutput) => {
    const currentTime = new Date().toLocaleTimeString(); // Get the current time
    const formattedOutput = `${currentTime}: ${newOutput}`; // Combine timestamp and newOutput

    setConsoleOutput((prevOutput) => prevOutput + "\n" + formattedOutput);
    // parseConsoleData(newOutput);
    scrollToBottom(); // Scroll to the bottom after each update
  };

  // discontinued. This was to change state of a button from React instead of from the source in python
  const parseConsoleData = (consoleData) => {
    const passIsPresent = consoleData.includes("PASS");
    const failIsPresent = consoleData.includes("FAIL");

    console.log(
      ">>> passIsPresent failIsPresent :",
      passIsPresent,
      failIsPresent
    );

    if (passIsPresent || failIsPresent) {
      buttons.forEach((button) => {
        const buttonLabel = button.label;
        const state = passIsPresent ? "pass" : "fail";
        const buttonEnabled = button.enabled;
        const labelNameIsPresent = consoleData.includes(buttonLabel);

        if (labelNameIsPresent) {
          console.log(">>> labelPresent : ", buttonLabel);
          console.log(">>> stateToSet   : ", state);
          setButtonState(buttonLabel, state, buttonEnabled); // Assuming you have a unique identifier for each button (e.g., id)
        }
      });
    }
  };

  const fetchButtonStatus = async () => {
    socket.emit("handleRequest", { type: "fetchButtonStatus", message: "" });
  };

  const fetchProjectName = async () => {
    socket.emit("handleRequest", { type: "fetchProjectName", message: "" });
  };

  const handleClick = async (label) => {
    socket.emit("handleClick", { type: "runPythonFunction", message: label });
  };

  const setButtonState = (buttonId, newState, newEnabled) => {
    setButtons((prevButtons) =>
      prevButtons.map((button) =>
        button.label === buttonId
          ? { ...button, state: newState, enabled: newEnabled }
          : button
      )
    );
  };

  const scrollToBottom = () => {
    consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
  };

  const downloadConsoleOutput = () => {
    const blob = new Blob([consoleOutput], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const date = new Date();
    const year = date.getFullYear().toString().padStart(2, "0");
    const month = date.getMonth().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedName = `${projectName}_${year}${month}${day}_${hours}${minutes}${seconds}.txt`; // Combine timestamp and newOutput
    const a = document.createElement("a");

    a.href = url;
    a.download = formattedName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const clearConsoleOutput = () => {
    setConsoleOutput(""); // Set consoleOutput state to an empty string
  };

  const handleShowHelp = () => {
    setHelpVisible(!isHelpVisible);
  };

  const handleCloseHelp = () => {
    setHelpVisible(false);
  };

  return (
    <div>
      <div className="App" style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            cursor: "pointer",
          }}
          // type="button"
          onClick={() => navigate("/")}
        >
          <img
            src={require("./back.png")}
            alt="Go back to main page"
            style={{
              width: "70px",
              height: "70px",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 98,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column", // Added to stack the headings vertically
          }}
        >
          <h3 />
          <h1 style={{ color: "rgba(219,92,39,1)", margin: "0" }}>
            BIT BUSTER v0.1
          </h1>
          <h3 style={{ color: "rgba(219,92,39,0.7)", margin: "0" }}>
            AUTOMATED CONTROL SYSTEM TESTER
          </h3>
          <h3 />
        </div>
        <div
          style={{
            flex: 1,
            cursor: "pointer",
          }}
          type="button"
          onClick={handleShowHelp}
        >
          <img
            src={require("./help.png")}
            alt="Get Help"
            style={{
              width: "75px",
              height: "75px",
              marginTop: "30px",
              marginRight: "30px",
            }}
          />
          <HelpOverlay
            isVisible={isHelpVisible}
            onClose={handleCloseHelp}
            imageName="help_project.png"
          />
        </div>
      </div>

      <div className="App" style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            padding: "10px",
            backgroundImage: 'url("BitBusterBackground.jpg")',
            backgroundSize: "cover", // Adjusts the size of the image to cover the entire div
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
            backgroundColor: "dark",
            color: "white",
            border: "2px solid #ccc",
          }}
        >
          {/* <h2 style={{ color: "#fff" }}>{"AUTO TESTER"}</h2> New heading */}
          <div
            className="App"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column", // Added to stack the headings vertically
            }}
          >
            <h3 />
            <h2 style={{ color: "#fff", margin: "0" }}>
              {projectName && `PROJECT :  ${projectName}`}
            </h2>
          </div>
          <h4 style={{ color: "#fff" }}>
            The project above has following interfaces available to test.
          </h4>{" "}
          {/* New heading */}
          <div className="master-container">
            <div
              className="divSTOP"
              key={"STOP"}
              style={{ margin: "5px" }}
              type="button"
              onClick={() => handleClick("STOP")}
            >
              {"STOP"}
            </div>
            <div></div>
            <div></div>
            <div
              className="divALL"
              key={"RUN ALL"}
              style={{ margin: "5px" }}
              type="button"
              onClick={() => handleClick("RUNALL")}
            >
              {"RUN ALL"}
            </div>
          </div>
          <div className="grid-container">
            {buttons.map(({ label, enabled, state }) => (
              <div
                key={label}
                style={{
                  margin: "5px",
                  pointerEvents: enabled ? "auto" : "none",
                  cursor: enabled ? "pointer" : "not-allowed",
                }}
                type="button"
                onClick={() => enabled && handleClick(label)}
                disabled={!enabled}
                className={
                  state === "pass"
                    ? "passButton"
                    : state === "fail"
                    ? "failButton"
                    : state === "inprogress"
                    ? "inProgressButton"
                    : ""
                }
              >
                {label}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            // marginLeft: "20px",
            border: "2px solid #ccc",
            padding: "0px",
            minHeight: "800px",
            maxHeight: "800px",
          }}
        >
          <div className="App" style={{ display: "flex" }}>
            <div
              style={{
                marginLeft: "150px",
                marginTop: "10px",
                flex: 10,
                padding: "0px",
              }}
            >
              <h2>CONSOLE OUTPUT</h2>
            </div>
            <div style={{ marginLeft: "0px", flex: 1, padding: "0px" }}>
              <div
                className="divConsoleButton"
                key={"divConsoleButton"}
                style={{
                  marginTop: "15px",
                  marginRight: "10px",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                type="button"
                onClick={clearConsoleOutput}
              >
                {"Clear Console"}
              </div>
            </div>
            <div style={{ marginLeft: "0px", flex: 1, padding: "0px" }}>
              <div
                className="divConsoleButton"
                key={"divConsoleButton"}
                style={{
                  marginTop: "15px",
                  marginRight: "15px",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                type="button"
                onClick={downloadConsoleOutput}
              >
                {"Download Console"}
              </div>
            </div>
          </div>

          <div
            ref={consoleRef}
            className="console"
            style={{ whiteSpace: "pre-wrap", border: "1px solid #ccc" }}
          >
            {/* {consoleOutput} */}
            {consoleOutput.split("\n").map((line, index) => {
              let style = { color: "white" }; // Default style is white text

              if (line.includes("FAIL")) {
                style = { color: "red" };
              } else if (line.includes("PASS")) {
                style = { color: "green" };
              }

              return (
                <div key={index} style={style}>
                  {line}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
